import cx from 'classnames';
import React from 'react';
import Hero from "../../../../assets/images/grace/hero.png";
import Image1 from "../../../../assets/images/grace/image-1.png";
import Image2 from "../../../../assets/images/grace/image-2.png";
import Image3 from "../../../../assets/images/grace/image-3.png";
import Image4 from "../../../../assets/images/grace/image-4.png";
import useMixpanel from '../../../hooks/useMixpanel';
import useModal from '../../../hooks/useModal';
import * as styles from './Grace.module.scss';
import GraceModal from './GraceModal';

const Grace = () => {
  const { isOpen, openModal, closeModal } = useModal();
  const trackEvent = useMixpanel();

  const handleClick = (e) => {
    e.preventDefault();
    trackEvent("Clicked Learn More");
    openModal();
  }

  return (
    <div className={cx("container", styles.container)}>
      <GraceModal isOpen={isOpen} closeModal={closeModal}/>
      <section className={cx("section-container", styles.heroContainer)}>
        <div className={cx("section", styles.heroContent)}>
          <div className={cx("section-text-container", styles.heroTextContainer)}>
            <div className={styles.logo} />
            <div className={styles.heroTitle}>
              <div className={styles.highlight}>Freelancer insurance</div><br/>
              {" "}that actually makes<br/> sense for a <div className={styles.highlight}>freelancer.</div>
            </div>
            <p>Grace helps you find insurance policies tailored to freelancers and can cover your payment if you're ever falling behind.</p>
            <button onClick={handleClick} className={styles.button}>Learn More</button>
          </div>
          <div className={styles.heroImgContainer}>
            <img src={Hero} alt="freelancer"/>
          </div>
        </div>
      </section>
      <section className={cx("section-container", styles.offwhiteBg)}>
        <div className={cx("section", "section--reverse")}>
          <div className={styles.sectionImgContainer}>
            <img src={Image1} />
          </div>
          <div className={cx("section-text-container", styles.sectionTextContainer)}>
            <h3 className={styles.sectionTitle}>
              Personalized <div className={styles.pinkText}>policy matching.</div>
            </h3>
            <p>Insurance isn't one size fits all. Grace helps you identify and select the best insurance products tailored to your unique needs as a freelancer. Just answer a few questions and you'll get personalized recommendations.</p>
          </div>
        </div>
      </section>
      <section className={cx("section-container", styles.offwhiteBg)}>
        <div className={cx("section", styles.listSection)}>
          <p>We help freelancers find tailored insurance solutions that provide financial security and peace of mind, including:</p>
          <div className={styles.list}>
            <div className={styles.listItem}>
              <h5>Income Protection</h5>
              <p>Replaces your income if you're unable to work due to illness or injury.</p>
            </div>
            <div className={styles.listItem}>
              <h5>Critical Illness</h5>
              <p>Covers serious medical conditions like heart attacks, strokes, or cancer.</p>
            </div>
            <div className={styles.listItem}>
              <h5>Short-term / Long-term Disability</h5>
              <p>Provides coverage if you become disabled and unable to work.</p>
            </div>
            <div className={styles.listItem}>
              <h5>Long-term Care</h5>
              <p>Helps cover the costs of care you may need as you age or if you have a chronic condition.</p>
            </div>
          </div>
          <p>…and more options, including coverage for maternity leave and planning for a secure financial future.</p>
        </div>
      </section>
      <section className={cx("section-container")}>
        <div className={cx("section")}>
          <div className={styles.sectionImgContainer}>
            <img src={Image2} />
          </div>
          <div className={cx("section-text-container", styles.sectionTextContainer)}>
            <h3 className={styles.sectionTitle}>
              Flexible <div className={styles.pinkText}>payment support.</div>
            </h3>
            <p>Our mission is to ensure that the ups and downs of freelance income don't interfere with your access to the stable, long-term benefits you need. If your income fluctuates and you can't make a payment, Grace will cover it for you until you're back on track.</p>
          </div>
        </div>
      </section>
      <section className={cx("section-container")}>
        <div className={cx("section", "section--reverse")}>
          <div className={styles.sectionImgContainer}>
            <img src={Image3} />
          </div>
          <div className={cx("section-text-container", styles.sectionTextContainer)}>
            <h3 className={styles.sectionTitle}>
              <div className={styles.pinkText}>Stress-free</div> insurance.
            </h3>
            <p>Grace's smart engine recommends policies that actually make sense for you- removing any chance for confusion or stress.</p>
          </div>
        </div>
      </section>
      <section className={cx("section-container")}>
        <div className={cx("section")}>
          <div className={styles.sectionImgContainer}>
            <img src={Image4} />
          </div>
          <div className={cx("section-text-container", styles.sectionTextContainer)}>
            <h3 className={styles.sectionTitle}>
              Need insurance for <div className={styles.pinkText}>your business?</div>
              We can do that too. 
            </h3>
            <p>Whether you need short-term coverage for a tradeshow or a photoshoot, or long-term general business insurance, we'll find the best plan for you.</p>
            <button onClick={handleClick} className={styles.button}>Learn More</button>
          </div>
        </div>
      </section>
      <section className={cx("section-footer", styles.sectionFooter)}>
        <div className={cx("section-footer-content", styles.sectionFooterContent)}>
          <div>We've got you <div className={styles.highlight}>covered.</div></div>
        </div>
      </section>
      <footer className={cx("footer", styles.footer)}>
        2024 Grace
      </footer>
    </div>
  )
}

export default Grace;