import mixpanel from "mixpanel-browser";
import { useEffect, useRef } from "react";

const useMixpanel = () => {
  const isInitialized = useRef();
  const token = window.MIXPANEL_TOKEN;
  
  useEffect(() => {
    if (!isInitialized.current && !!token) {
      mixpanel.init(token, {
        debug: true,
        track_pageview: "url-with-path-and-query-string",
        persistence: "localStorage"
      })
      isInitialized.current = true;
    }
  }, []);

  const trackEvent = (event, data = null) => {
    if(!!token) {
      mixpanel.track(event, data);
    }
  }

  return trackEvent;
}

export default useMixpanel;