import cx from "classnames";
import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import useMixpanel from "../../../../hooks/useMixpanel";
import { isValidEmail } from "../../../../utils/email";
import * as styles from "./GraceModal.module.scss";

const GraceModal = ({isOpen, closeModal}) => {
  const trackEvent = useMixpanel();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [age, setAge] = useState('');
  const [interests, setInterests] = useState({
    "Income Protection": false,
    "Critical Illness": false,
    "Disability": false,
    "Long-Term Care": false,
    "Other": false,
    "Unsure": false,
  });

  const handleCloseModal = () => {
    closeModal();
    if(emailSubmitted && error && !hasSubmitted) {
      setEmailSubmitted(false);
    }
  }

  const chosenInterests = Object.keys(interests).reduce((acc, key) => {
    if(interests[key]) {
      acc.push(key);
    }
    return acc;
  }, []).join(", ");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handleAgeChange = (e) => {
    setAge(e.target.value);
  }

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setInterests((prevInterests) => ({
      ...prevInterests,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    if(!emailSubmitted && !!chosenInterests) {
      if(!isValidEmail(email)) {
        setError("Please enter a valid email.");
        return;
      }
      setEmailSubmitted(true);
      return;
    }
    try {
      const response = await fetch('/email-submissions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        body: JSON.stringify({
          email_submission: {
            email,
            site: "Grace",
            metadata: {
              interests: chosenInterests,
              age
            }
          }
        })
      });
      const json = await response.json();
      if(!response.ok) {
        if(json.error) {
          setError(json.message[0]);
        } else {
          throw new Error("Failed to submit data", response);
        }
      } else {
        trackEvent("Submitted Email", { "Email": email });
        if(chosenInterests) {
          trackEvent("Selected insurance types", { "Insurance Type(s)": chosenInterests });
        }
        if(age) {
          trackEvent("Submitted age", { "Age": age });
        }
        setHasSubmitted(true);
      }
    } catch (error) {
      console.error("ERROR:", error);
      setEmailSubmitted(false);
      setError("Oops! Something went wrong. Please try again later.");
    }
  }
  
  return (
    <Modal 
      open={isOpen} 
      onClose={handleCloseModal} 
      center
      classNames={{
        root: "modal-root",
        overlay: cx("modal-overlay", styles.overlay),
        modal: cx("modal", styles.modal),
        closeIcon: styles.closeIcon
      }}
    >
      <div className={cx("modal-content")}>
        {
          !hasSubmitted && <>
            <form onSubmit={handleSubmit}>
              {!emailSubmitted && 
                <>
                  <h2 className={cx("title", styles.title)}>Tell us what you're interested in and where we can reach out to you</h2>
                  <div className={styles.options}>
                    <label>
                      <input
                        type="checkbox"
                        name="Income Protection"
                        checked={interests["Income Protection"]}
                        onChange={handleCheckboxChange}
                      />
                      Income Protection
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="Critical Illness"
                        checked={interests["Critical Illness"]}
                        onChange={handleCheckboxChange}
                      />
                      Critical Illness
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="Disability"
                        checked={interests["Disability"]}
                        onChange={handleCheckboxChange}
                      />
                      Disability
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="Long-Term Care"
                        checked={interests["Long-Term Care"]}
                        onChange={handleCheckboxChange}
                      />
                      Long-Term Care
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="Other"
                        checked={interests["Other"]}
                        onChange={handleCheckboxChange}
                      />
                      Other
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="Unsure"
                        checked={interests["Unsure"]}
                        onChange={handleCheckboxChange}
                      />
                      I'm not sure. Can you tell me where to start?
                    </label>
                  </div>
                  <div className={styles.rowDesktop}>
                    <input type="text" placeholder="Enter your email" value={email} onChange={handleEmailChange} />
                    <button className={cx(styles.button)} onClick={handleSubmit}>Submit</button>
                  </div>
                </>
              }
              { emailSubmitted &&
                <>
                  <h2 className={cx("title", styles.title)}>Share your age to help us  recommend the best insurance products.</h2>
                  <div className={styles.options}>
                    <label>
                      <input
                        type="radio"
                        name="18-34"
                        checked={age === "18-34"}
                        value={"18-34"}
                        onChange={handleAgeChange}
                      />
                      18-34
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="35-50"
                        checked={age === "35-50"}
                        value="35-50"
                        onChange={handleAgeChange}
                      />
                      35-50
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="51-65"
                        checked={age === "51-65"}
                        value="51-65"
                        onChange={handleAgeChange}
                      />
                      51-65
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="66+"
                        checked={age === "66+"}
                        value="66+"
                        onChange={handleAgeChange}
                      />
                      66+
                    </label>
                  </div>
                  <button className={cx(styles.button, styles.submitButton)} onClick={handleSubmit}>Submit</button>
                </>
              }
            </form>
            {error && <p className={cx("error", styles.error)}>{error}</p>}
          </>
        }
        {
          hasSubmitted && <>
            <h2 className={cx("title", styles.title)}>Thanks!</h2>
            <button className={cx("button", styles.button)} onClick={closeModal}>Close</button>
          </>
        }
      </div>
    </Modal>
  )
}

export default GraceModal;